<template>
    <div class="profile-statistics-home">
        <v-row class="justify-space-around pa-3">
            <v-col class="pa-1">
                <!-- @click="openDialog('tp', $event)" -->
                <v-card
                    elevation="7"
                    style="z-index: 1000"
                    class="fill-height white--text ma-1 justify-center primary d-flex flex-column rounded-lg align-center"
                >
                    <lottie-player
                        mode="normal"
                        class="settings__item-lottie"
                        loop
                        style="width: 45px; height: 45px"
                        autoplay
                        src="/img/lottiefiles/mytraining/workoutTrophy.json"
                    ></lottie-player>
                    <span class="h6">Trainingspläne</span>
                    <number class="h2" :from="0" :to="userStats.planDone"></number>
                    <span>Abgeschlossen</span>
                </v-card>
            </v-col>

            <!-- @click="openDialog('gf', $event)" -->
            <v-col cols="6" class="pa-1">
                <v-card
                    elevation="7"
                    class="darkenprimary2 white--text fill-height align-center pa-1 rounded-lg d-flex flex-column"
                >
                    <lottie-player
                        mode="normal"
                        class="settings__item-lottie"
                        loop
                        autoplay
                        style="width: 45px; height: 45px"
                        autoplay
                        src="/img/lottiefiles/mytraining/groupFitnessTrophy.json"
                    ></lottie-player>
                    <span class="h7">Gruppenkurse</span>
                    <number class="h5 mb-0" :from="0" :to="userStats.groupfitnessDone"></number>
                </v-card>
            </v-col>
            <v-col class="pa-1" cols="6">
                <v-card
                    @click="openCheckinCalendar()"
                    elevation="7"
                    class="fill-height white--text justify-center ma-1 primary d-flex flex-column rounded-lg align-center"
                >
                    <lottie-player
                        mode="normal"
                        class="settings__item-lottie"
                        loop
                        autoplay
                        style="width: 45px; height: 45px"
                        src="/img/lottiefiles/mytraining/calendar.json"
                    ></lottie-player>
                    <span class="h6">Check-Ins</span>
                    <number
                        v-if="user && user.checkins && user.checkins.length > 0"
                        class="h2"
                        :from="0"
                        :to="user.checkins.length"
                    ></number>
                    <span>Seit {{ firstCheckIn }}</span>
                </v-card>
            </v-col>
            <v-col cols="6" class="pa-1">
                <v-card
                    elevation="7"
                    class="darkenprimary white--text align-center fill-height pa-1 mt-2 rounded-lg d-flex flex-column"
                >
                    <lottie-player
                        mode="normal"
                        class="settings__item-lottie"
                        loop
                        autoplay
                        style="width: 45px; height: 45px"
                        autoplay
                        src="/img/lottiefiles/mytraining/clock.json"
                    ></lottie-player>
                    <span class="h7">Zeit</span>
                    <div>
                        <number class="h5 mb-0" :from="0" :to="userStats.time.toFixed(0)"></number>
                        <span style="font-size: 12px">Minuten</span>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="6">
                <v-card class="d-flex flex-column pa-1 rounded-lg fill-height" @click="toggleWeightDialog()">
                    <span style="margin-bottom: -20px" class="font-weight-bold grey--text">Gewicht</span>
                    <apexchart
                        class="ma-auto rounded-0"
                        :options="weightChartOptions"
                        :series="weightChartItems"
                    ></apexchart>
                    <div>
                        <number class="h5 mb-0" :from="0" :to="userWeight"></number>
                        <span>KG</span>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="6" class="d-flex flex-column">
                <v-card class="rounded-lg fill-height" @click="activateHeightDialog">
                    <v-card-subtitle class="py-0 grey--text text-left">Grösse</v-card-subtitle>
                    <v-card-title class="py-0 user-info-card-title">
                        {{ user.groesse ? user.groesse + 'cm' : 'eintragen' }}
                    </v-card-title>
                </v-card>
                <v-card class="rounded-lg my-1 fill-height" @click="activateAgeDialog">
                    <v-card-subtitle class="py-0 grey--text text-left">Alter</v-card-subtitle>
                    <v-card-title class="py-0 user-info-card-title">
                        {{
                            user.geburtstag
                                ? $moment().diff($moment(user.geburtstag, 'YYYY-MM-DD'), 'years', false) + ' Jahre'
                                : 'noch nicht eingetragen'
                        }}
                    </v-card-title>
                </v-card>
                <v-card class="rounded-lg fill-height">
                    <v-card-subtitle class="py-0 grey--text text-left">BMI</v-card-subtitle>
                    <v-card-title class="user-info-card-title py-0" v-bind:style="bmiColor">
                        {{ bmiValue && bmiValue < 60 ? bmiValue : 'berechnen' }}
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog
            class="overflow-hidden"
            max-width="200px"
            v-model="dialog.active"
            @click:outside="saveDialog"
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card-title class="pa-0">{{ dialog.title }}</v-card-title>
                <v-text-field class="pa-0" v-if="dialog.title === 'Grösse'" v-model="user.groesse"></v-text-field>
                <v-text-field class="pa-0" type="date" v-else v-model="birthdate"></v-text-field>
            </v-card>
        </v-dialog>
        <profile-statistics-weight-dialog
            v-if="weightDialog"
            :user="user"
            @close="toggleWeightDialog"
        ></profile-statistics-weight-dialog>
        <v-dialog max-width="345px" v-model="checkinCalendarActive" class="d-flex">
            <v-date-picker locale="de-CH" v-model="activeDate" :events="formattedCheckins"></v-date-picker>
            <div class="white pa-2">
                <span v-if="activeDateCheckin">
                    Du hast dich an diesem Tag um {{ activeDateCheckin }} Uhr ins Studio eingecheckt.
                </span>
                <span v-else>Du hast dich an diesem Tag nicht eingecheckt.</span>
            </div>
        </v-dialog>
        <v-dialog fullscreen v-model="activeDialog" content-class="statisticDialog" :origin="dialogOrigin">
            <v-card style="background-color: white">
                <div style="background-color: #f4fdf2; height: 100vh">
                    <v-btn @click="activeDialog = null" absolute icon top right large>
                        <v-icon class="primary--text">mdi-close</v-icon>
                    </v-btn>
                    <div v-if="activeDialogType === 'tp'">
                        <v-card-title>
                            <lottie-player
                                mode="normal"
                                class="settings__item-lottie"
                                loop
                                style="width: 45px; height: 45px"
                                autoplay
                                src="/img/lottiefiles/mytraining/workoutTrophy.json"
                            ></lottie-player>
                            <span class="font-weight-bold">Trainingsplan Statistik</span>
                        </v-card-title>
                        <v-card-text></v-card-text>
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from '../api'
import profileStatisticsWeightDialog from './profileStatisticsWeightDialog.vue'

export default {
    components: {
        profileStatisticsWeightDialog,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            activeDate: this.$moment().format('YYYY-MM-DD'),
            checkinCalendarActive: false,
            birthdate: null,
            dialog: {
                active: false,
                title: '',
            },
            userStats: {
                groupfitnessDone: 0,
                planDone: 0,
                time: 0,
            },
            weightDialog: false,
            activeDialog: null,
            activeDialogType: '',
            dialogOrigin: null,
        }
    },
    methods: {
        openDialog(type, $element) {
            const { top, left, width, height } = $element.target.getBoundingClientRect()
            this.dialogOrigin = Math.round(left + 100) + 'px' + ' ' + Math.round(top + 50) + 'px '
            this.activeDialog = true
            this.activeDialogType = type
        },

        openCheckinCalendar() {
            this.checkinCalendarActive = true
        },
        saveDialog() {
            this.dialog.title === 'Grösse'
                ? api.updateUserInfo({ groesse: this.user.groesse })
                : api.updateUserInfo({ geburtstag: this.$moment(this.birthdate).format('YYYY-MM-DD') })
            if (this.dialog.title !== 'Grösse') this.user.geburtstag = this.$moment(this.birthdate, 'YYYY-MM-DD')
        },

        activateHeightDialog() {
            this.dialog = { active: true, title: 'Grösse' }
        },
        activateAgeDialog() {
            this.birthdate = this.$moment(this.user.geburtstag, 'YYYY-MM-DD')
            this.dialog = { active: true, title: 'Alter' }
        },
        toggleWeightDialog() {
            this.weightDialog = !this.weightDialog
        },
    },
    async created() {
        this.birthdate = this.$moment(this.user.geburtstag)
        this.userStats = await api.fetchUserProfileStats()
    },
    computed: {
        activeDateCheckin() {
            let checkin = this.user.checkins.find((checkIn) => {
                console.log(this.$moment(checkIn).format('YYYY-MM-DD'))
                console.log(this.activeDate)
                return (
                    this.$moment(checkIn).format('YYYY-MM-DD') ===
                    this.$moment(this.activeDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
                )
            })
            console.log(checkin)
            return checkin ? this.$moment(checkin).format('HH:mm') : null
        },
        formattedCheckins() {
            return this.user.checkins.map((checkin) => {
                return this.$moment(checkin).format('YYYY-MM-DD')
            })
        },
        firstCheckIn() {
            return this.$moment(this.user.checkins[0]).format('DD.MM.YYYY')
        },
        weightChartItems() {
            let data = []
            if (this.user.gewicht) data.push(this.user.gewicht)
            if (this.user.weights && this.user.weights.length) {
                this.user.weights.forEach((weight) => {
                    data.push(weight.value)
                })
            }
            if (this.targetWeight) data.push(this.targetWeight)
            return [
                {
                    name: 'Gewicht',
                    data,
                },
            ]
        },
        weightChartOptions() {
            return {
                chart: {
                    toolbar: {
                        show: false,
                    },
                    type: 'area',
                },
                grid: {
                    show: false,
                },
                markers: {
                    size: 3,
                },
                stroke: {
                    width: 1,
                },
                dataLabels: {
                    enabled: false,
                },
                yaxis: {
                    show: false,
                },
                xaxis: {
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                colors: [
                    this.$vuetify.theme.themes.light.primary,
                    this.$vuetify.theme.themes.light.darkenprimary1,
                    this.$vuetify.theme.themes.light.darkenprimary2,
                ],
            }
        },
        bmiValue() {
            return (
                (Number(this.userWeight) / (Number(this.user.groesse) * Number(this.user.groesse))) *
                10000
            ).toFixed(2)
        },
        bmiColor() {
            if (this.bmiValue < 18.5) return { color: 'cornflowerblue' }
            if (this.bmiValue < 24) return { color: 'limegreen' }
            if (this.bmiValue < 30) return { color: 'green' }
            if (this.bmiValue < 35) return { color: 'orange' }
            if (this.bmiValue < 40) return { color: 'darkgoldenrod' }
            return { color: 'red' }
        },
        userWeight() {
            if (this.user.weights && this.user.weights.length)
                return this.user.weights[this.user.weights.length - 1].value
            if (this.user.gewicht) {
                return this.user.gewicht
            }
            return 0
        },
    },
}
</script>

<style lang="scss" scoped>
.user-info-card-title {
    line-height: 1.25rem !important;
}
.profile-statistics-home {
    width: 100%;
}
::v-deep .v-dialog {
    transition: 1s cubic-bezier(0.25, 0.8, 0.25, 1);
}
</style>
