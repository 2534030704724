<template>
	<div style="width: 100%;">
		<!--

	<v-tabs centered v-model="tab">
			<v-tabs-slider color="primary"></v-tabs-slider>

			<v-tab v-for="item in tabItems" :key="item.value">
				{{ item.title }}
			</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tab">
			<v-tab-item :key="'home'"> <profile-statistics-home :user="user"></profile-statistics-home> </v-tab-item>
			<v-tab-item :key="'day'">
				<lottie-player
					mode="normal"
					class="settings__item-lottie ma-auto"
					loop
					autoplay
					style="width:200px;height:200px;"
					autoplay
					src="/img/lottiefiles/underConstruction.json"
				></lottie-player>
				<v-card-title style="word-break: break-word" class="primary--text"
					>Hier wirst du bald deine Trainingserfolge nachverfolgen können.</v-card-title
				>
			</v-tab-item>
			<v-tab-item :key="'week'">
				<lottie-player
					mode="normal"
					class="settings__item-lottie ma-auto"
					loop
					autoplay
					style="width:200px;height:200px;"
					autoplay
					src="/img/lottiefiles/underConstruction.json"
				></lottie-player>
				<v-card-title style="word-break: break-word" class="primary--text"
					>Hier wirst du bald deine Trainingserfolge nachverfolgen können.</v-card-title
				></v-tab-item
			>
			<v-tab-item :key="'month'">
				<lottie-player
					mode="normal"
					class="settings__item-lottie ma-auto"
					loop
					autoplay
					style="width:200px;height:200px;"
					autoplay
					src="/img/lottiefiles/underConstruction.json"
				></lottie-player>
				<v-card-title style="word-break: break-word" class="primary--text"
					>Hier wirst du bald deine Trainingserfolge nachverfolgen können.</v-card-title
				></v-tab-item
			>
			<v-tab-item :key="'year'">
				<lottie-player
					mode="normal"
					class="settings__item-lottie ma-auto"
					loop
					autoplay
					style="width:200px;height:200px;"
					autoplay
					src="/img/lottiefiles/underConstruction.json"
				></lottie-player>
				<v-card-title style="word-break: break-word" class="primary--text"
					>Hier wirst du bald deine Trainingserfolge nachverfolgen können.</v-card-title
				></v-tab-item
			>
		</v-tabs-items> -->
		<profile-statistics-home :user="user"></profile-statistics-home>
	</div>
</template>

<script>
import api from '../api'
import profileStatisticsHome from './profileStatisticsHome.vue'
export default {
	components: {
		profileStatisticsHome,
	},
	props: {
		user: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			tab: 'home',
			tabItems: [
				{ title: 'Home', value: 'home' },
				{ title: 'Tag', value: 'day' },
				{ title: 'Woche', value: 'week' },
				{ title: 'Monat', value: 'month' },
				{ title: 'Jahr', value: 'year' },
			],
		}
	},
	created() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
::v-deep .v-tab {
	font-size: 12px !important;
	min-width: unset !important;
}
</style>
