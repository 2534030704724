<template>
    <!-- 10.09.2020 -->
    <div>
        <v-row class="profile" :class="{ 'profile--closed': activeSlide }">
            <v-col cols="8" class="pa-5">
                <v-avatar class="profile-avatar mb-3" size="125">
                    <slim-cropper v-if="loaded" :options="slimOptions" style="width: 125px; height: 125px">
                        <img v-if="oldUser.bild" :src="'/img/profilbilder' + oldUser.bild" alt="" />
                        <input type="file" accept="image/*" />
                    </slim-cropper>
                </v-avatar>
            </v-col>
            <v-col class="profile-joined d-flex flex-column" cols="4">
                <span class="mt-auto caption grey--text"> Beigetreten </span>
                <span class="mb-auto font-weight-bold h7 primary--text">
                    {{ oldUser.datum ? $moment.unix(oldUser.datum).format('MM.YYYY') : '09.2020' }}
                </span>
            </v-col>

            <v-col cols="12 pb-0">
                <span class="h2 font-weight-bold">{{ oldUser.vorname }}</span></v-col
            >
            <v-col cols="12 pt-0"
                ><span class="h2 grey--text">{{ oldUser.nachname }}</span></v-col
            >
            <!--	<v-col> {{ oldUser.email }}</v-col> -->
        </v-row>
        <swiper class="mt-5" style="background: white" :options="profileSwiperOptions" ref="profileSwiper">
            <swiper-slide>
                <v-row class="px-3" v-for="item in listItems" :key="'listitem' + item.text">
                    <v-col cols="3">
                        <div class="profile-list-icon">
                            <lottie-player
                                mode="normal"
                                class="settings__item-lottie"
                                style="width: 45px; height: 45px"
                                :loop="item.value !== 'signoff'"
                                :src="item.icon"></lottie-player>
                        </div>
                    </v-col>

                    <v-col cols="6" class="px-0 align-self-center">
                        <span class="font-weight-bold">{{ item.text }} </span>
                    </v-col>
                    <v-col cols="3">
                        <div @click="setSlideActive(item.value)">
                            <lottie-player
                                mode="normal"
                                class="settings__item-lottie"
                                style="width: 45px; height: 45px"
                                :loop="false"
                                src="/img/lottiefiles/arrowButton.json"></lottie-player>
                        </div>
                    </v-col>
                </v-row>
            </swiper-slide>
            <swiper-slide>
                <v-row>
                    <v-col cols="3" @click="backButtonClicked()">
                        <lottie-player
                            mode="normal"
                            class="settings__item-lottie"
                            style="width: 45px; height: 45px"
                            src="/img/lottiefiles/arrowBackButton.json"
                            :loop="false"></lottie-player>
                    </v-col>
                    <v-col cols="9" class="align-self-center">
                        <v-card-title class="pa-0">
                            {{ getActiveSlideTitle }}
                        </v-card-title>
                    </v-col>
                </v-row>

                <div v-if="activeSlide === 'stats'" class="d-flex flex-column align-items-center text-center">
                    <profile-statistics :user="oldUser"></profile-statistics>
                </div>
                <div v-if="activeSlide === 'payments'" class="d-flex flex-column align-items-center text-center mt-5">
                    <v-expansion-panels v-model="paymentModel" dark v-if="payments">
                        <v-expansion-panel v-for="(payment, i) of payments" :key="i + '-paymentIndex'">
                            <v-expansion-panel-header
                                >{{ $moment(payment.createdAt).format('DD.MM.YY HH:mm') }} Uhr |
                                {{ statusMapper[payment.status] }}</v-expansion-panel-header
                            >
                            <v-expansion-panel-content
                                ><payment-entry
                                    :paddingTable="false"
                                    :showIcon="true"
                                    :hideDate="true"
                                    :paymentEntry="payment"></payment-entry
                            ></v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-alert v-else type="info" class="mt-5" elevation="2" tile>
                        Du hast noch keine Zahlungen getätigt.
                    </v-alert>
                </div>
                <div v-if="activeSlide === 'signoff'" class="d-flex flex-column align-items-center">
                    <lottie-player
                        mode="normal"
                        class="settings__item-lottie"
                        loop
                        autoplay
                        style="width: 200px; height: 200px"
                        src="/img/lottiefiles/exiting.json"></lottie-player>
                </div>

                <div v-if="activeSlide === 'notifications'">
                    <v-checkbox label="E-Mail Benachrichtigungen" v-model="oldUser.notification_email"></v-checkbox>
                </div>
                <div v-if="activeSlide === 'profile'">
                    <v-form>
                        <v-text-field label="E-Mail" v-model="oldUser.email" type="email" placeholder="Deine E-mail">
                        </v-text-field>

                        <v-text-field label="Vorname" v-model="oldUser.vorname" placeholder="Dein Vorname"> </v-text-field>

                        <v-text-field label="Nachname" v-model="oldUser.nachname" placeholder="Dein Nachname">
                        </v-text-field>

                        <v-text-field label="Adresse 1" v-model="oldUser.adresse_1" placeholder="Deine Adresse">
                        </v-text-field>

                        <v-text-field label="Adresse 2" v-model="oldUser.adresse_1" placeholder="Deine erweiterte Adresse">
                        </v-text-field>

                        <v-text-field label="PLZ" type="number" v-model="oldUser.plz" placeholder="Deine PLZ">
                        </v-text-field>

                        <v-text-field label="Stadt" type="text" v-model="oldUser.stadt" placeholder="Deine Stadt">
                        </v-text-field>

                        <v-text-field label="Land" type="text" v-model="oldUser.land" placeholder="Dein Land">
                        </v-text-field>

                        <v-text-field label="Telefon" type="tel" v-model="oldUser.telefon" placeholder="Deine Telefonnummer">
                        </v-text-field>

                        <div class="noborder form-group text-danger" v-show="error">
                            {{ error }}
                        </div>
                    </v-form>
                    <v-btn color="red" class="mb-7 white--text" @click="deleteUser">Account löschen</v-btn>
                </div>
                <!--
				<div v-if="activeSlide === 'myBody'">
					<v-row class="justify-space-around mt-5">
						<v-col cols="3" class="pa-0">
							<v-card height="80px" style="background: darkgrey" class="d-flex flex-column text-center" elevation="7">
								<span class="mt-auto h4 font-weight-bold white--text mb-0">{{ oldUser.gewicht }}</span>
								<span class="mb-auto h7 white--text">Gewicht</span>
							</v-card>
						</v-col>
						<v-col cols="3" class="pa-0">
							<v-card height="80px" class="grey d-flex flex-column text-center" elevation="7">
								<span class="mt-auto h4 font-weight-bold white--text mb-0"> {{ oldUser.groesse }} </span>
								<span class="mb-auto h7 white--text">Grösse</span>
							</v-card></v-col
						>
					</v-row>
				</div>
				-->
            </swiper-slide>
        </swiper>
        <!--
     
		</v-card-text>
		<v-card-actions class="justify-center">
			<v-btn color="primary" @click="editAccount" :disabled="!true">
				Speichern
			</v-btn>
		</v-card-actions>-->
        <anamnesebogen ref="anamnesebogen"></anamnesebogen>
        <anamnese-bogen-create ref="anamneseBogenRef"></anamnese-bogen-create>
    </div>
</template>

<script>
import anamneseBogenCreate from '../components/anamneseBogenCreate'
import api from '../api'
import Slim from '../plugins/slimImage/slim/slim.vue'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import Anamnesebogen from '../components/Anamnesebogen'
import profileStatistics from './profileStatistics.vue'
import userService from '@/services/userService'
import PaymentEntry from '@/views/dashboard/payment/PaymentEntry.vue'
export default {
    components: {
        anamneseBogenCreate,
        Anamnesebogen,
        'slim-cropper': Slim,
        Swiper,
        SwiperSlide,
        profileStatistics,
        PaymentEntry,
    },
    directives: {
        swiper: directive,
    },
    data() {
        return {
            statusMapper: {
                init: 'Offen',
                initialized: 'Offen',
                authenticated: 'Authentifiziert',
                authorized: 'Autorisiert',
                settled: 'Abgerechnet',
                canceled: 'Abgebrochen',
                transmitted: 'Übermittelt',
                failed: 'Fehlgeschlagen',
                created: 'Offen',
                deprecated: 'Abgebrochen',
            },
            userService,
            activeSlide: null,
            paymentModel: 0,
            listItems: [
                {
                    icon: '/img/lottiefiles/profileInformations.json',
                    text: 'Profil',
                    route: 'profile/mybody',
                    value: 'profile',
                },

                {
                    icon: '/img/lottiefiles/increasingStat.json',
                    text: 'Mein Training',
                    route: 'profile/mybody',
                    value: 'stats',
                },
                {
                    icon: '/img/lottiefiles/profileInformations.json',
                    text: 'Zahlungen',
                    route: 'profile/payments',
                    value: 'payments',
                },
                {
                    icon: '/img/lottiefiles/notificationBell.json',
                    text: 'Benachrichtigungen',
                    route: 'profile/mybody',
                    value: 'notifications',
                },
                {
                    icon: '/img/lottiefiles/my-anamnese.json',
                    text: 'Mein Anamnesebogen',
                    route: 'profile/mybody',
                    value: 'my-anamnese',
                },
                {
                    icon: '/img/lottiefiles/signoff.json',
                    text: 'Abmelden',
                    value: 'signoff',
                },
            ],
            error: null,
            oldUser: {
                email: null,
                vorname: null,
                nachname: null,
                adresse_1: null,
                adresse_2: null,
                plz: null,
                stadt: null,
                land: null,
                telefon: null,
                geburtstag: null,
                gewicht: null,
                groesse: null,
                bild: null,
                fitnesslevel: null,
                notification_email: null,
            },
            loaded: false,
            oldUserBackup: null,
            payments: null,
        }
    },
    created() {
        let user_email = JSON.parse(localStorage.getItem('user')).email
        api.getUser()
            .then((data) => {
                this.oldUser = data.user
                this.oldUserBackup = JSON.stringify(data.user)
                this.loaded = true
            })
            .catch((err) => {
                console.log(err)
            })

        if (!this.active_slide && this.$route.query.active) {
            setTimeout((e) => {
                this.setSlideActive(this.$route.query.active)
                const currentRoute = this.$router.currentRoute

                // Remove all the queries from the current route
                const newRoute = { path: currentRoute.path }

                // Replace the current route with the new route that has no queries
                this.$router.replace(newRoute)
            }, 1000)
        }

        this.fetchPayments()
    },
    methods: {
        async fetchPayments() {
            this.payments = null
            let userId = this.userService.getUserId()
            if (userId) {
                let paymentsLoaded = await api.getPaymentsForUser(userId)
                console.log(paymentsLoaded)
                this.payments = paymentsLoaded
            }
        },
        deleteUser() {
            if (confirm('Wollen Sie Ihren Account wirklich löschen?')) {
                api.deleteOwnAccount()
                    .then((data) => {
                        // this.logout()
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        },
        showAnamnesebogen() {
            api.getUserAnamnesebogen()
                .then((data) => {
                    if (data.status == 2) {
                        alert('Es wurde noch kein Anamnesebogen hinterlegt')
                    } else {
                        if (data.date) {
                            this.$refs['anamnesebogen'].show(data, 1)
                        } else {
                            this.$refs.anamneseBogenRef.setVisible(data._id)
                        }
                    }
                })
                .catch((error) => {})
        },
        backButtonClicked() {
            if (this.activeSlide === 'profile' || this.activeSlide === 'notifications') this.editAccount()
            this.swiper.slideTo(0, 300, false)
            this.activeSlide = null
        },
        logout() {
            // token
            // user info
            localStorage.removeItem('token')
            localStorage.removeItem('user')

            location.reload()
        },
        setSlideActive(value) {
            if (value === 'my-anamnese') return this.showAnamnesebogen()
            if (value === 'signoff') this.logout()
            this.activeSlide = value
            this.swiper.slideTo(1, 500, false)
        },

        getProfilePicture(picture) {
            // let profilePicture = picture ? picture : '/uploads/default.png'
            let profilePicture = '/uploads/default.png'
            return 'https://app.wellcomefit.ch/img/profilbilder' + profilePicture
        },
        uploadPicture(formdata, progress, success, failure, slim) {
            const formData = new FormData()
            formData.append('file', formdata[0])

            let uploadimg = {}
            uploadimg.art = 'pfp'
            uploadimg.formData = formData
            api.uploadImage(uploadimg)
                .then((data) => {
                    if (data.status) {
                        this.oldUser.bild = data.name
                        success(data)
                    }
                })
                .catch((error) => {
                    failure(error)
                    console.log(error)
                })
        },
        editAccount() {
            let userEdited = JSON.stringify({
                oldUser: this.oldUserBackup,
                newUser: JSON.stringify(this.oldUser),
            })

            api.editUser(userEdited)
                .then((data) => {
                    let oldUserLocal = JSON.parse(localStorage.getItem('user'))

                    localStorage.removeItem('user')
                    let userNewLocal = {
                        _id: this.oldUser._id,
                        email: this.oldUser.email,
                        vorname: this.oldUser.vorname,
                        nachname: this.oldUser.nachname,
                        isAdmin: oldUserLocal.isAdmin,
                        studio_id: oldUserLocal.studio_id,
                    }

                    localStorage.setItem('user', JSON.stringify(userNewLocal))
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    },
    computed: {
        swiper: function () {
            return this.$refs.profileSwiper.$swiper
        },
        slimOptions() {
            return {
                ratio: '1:1',
                buttonCancelLabel: 'Abbrechen',
                buttonConfirmLabel: 'Bestätigen',
                label: 'Bild hochladen',
                labelLoading: 'Bild wird hochgeladen',
                statusUploadSuccess: 'gespeichert',
                service: this.uploadPicture,
                serviceFormat: 'file',
                dataJpegCompression: 70,
                instantEdit: true,
                push: true,
                initialImage: '/img/einmalig/imageUploadPlaceholderPFP.jpg',
            }
        },
        profileSwiperOptions: function () {
            return {
                allowTouchMove: false,
            }
        },
        getActiveSlideTitle() {
            if (!this.activeSlide) return 'Fehler! Bitte neuladen'
            return this.listItems.find((item) => item.value === this.activeSlide).text
        },
    },
}
</script>

<style lang="scss" scoped>
.profile {
    height: 35vh;
    visibility: unset;
    transition: height 0.3s ease-in;
    &--closed {
        transition: height 0.5s ease-out, visibility 0.5s ease-out;
        visibility: hidden;
        height: 0vh;
        ::v-deep .slim-btn {
            display: none !important;
        }
    }
    &-list {
        &-icon {
            width: 45px;
            background: lightgrey;
            border-radius: 50px;
        }
    }
    &__lottie {
        display: unset;
    }
    &-avatar {
        margin: auto !important;
    }
    &-joined {
        border: 1px solid var(lightgrey);
        border-bottom-width: 1px;
        -webkit-box-shadow: -38px 4px 0px -35px lightgrey;
        -moz-box-shadow: -38px 4px 0px -35px lightgrey;
        box-shadow: -38px 4px 0px -35px lightgrey;
    }
}
.profile-pic {
    height: 50px;
    width: 50px;
    background-color: grey;
    border-radius: 45px;
    border: 3px solid #4a4a4a;
    object-fit: cover;
}
</style>
